import { FanRouter, CreatorRouter, LandingRouter, MarketPlaceRouter } from "@utils/app-router";
import moment from "moment";
import capitanes from "../assets/images/favicon/captinaes-favicon.ico";
import teamGermany from "../assets/images/favicon/team-germany.ico";
import greenwich from "../assets/images/favicon/greenwich.png";
import magicgermany from "../assets/images/favicon/magicgermany.jpg";
import axios from "axios";
import lvlights from "../assets/images/favicon/lvlights.png";
import Management from "@services/management-service";
export const descendingOreder = (array, needle) => {
  return (array || []).sort((a, b) => b[needle] - a[needle]);
};

export const numberFormat = (number) => {
  // Format the number.
  let iNumberFormat = Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
  });

  return iNumberFormat.format(number || 0);
};

export const currencyFormat = (number) => {
  // Format the price to USD using their locales.
  let iCurrencyFormat = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return iCurrencyFormat.format(number || 0);
};

export const capitalize = (str) => {
  return (str || "").charAt(0).toUpperCase() + (str || "").slice(1);
};

export const getApp = (ContextPage) => {
  const hostname =
    window.location.hostname.split(".")[
      process.env.REACT_APP_HOST_POSITION ?? 0
    ];
  if (
    window.location.hostname === process.env.REACT_APP_MAIN_DOMAIN ||
    hostname === "www"
  ) {
    return <LandingRouter ContextPage={ContextPage} />;
  } else if (hostname === process.env.REACT_APP_CREATOR_ROUTE) {
    return <CreatorRouter ContextPage={ContextPage} />;
  } else if (hostname === 'marketplace') {
    return <MarketPlaceRouter ContextPage={ContextPage} />;
  }
  return <FanRouter ContextPage={ContextPage} />;
};

export const getHostName = () => {
  const hostname =
    window.location.hostname.split(".")[
      process.env.REACT_APP_HOST_POSITION ?? 0
    ];
  return hostname;
};

export const getAppName = () => {
  const hostname =
    window.location.hostname.split(".")[
      process.env.REACT_APP_HOST_POSITION ?? 0
    ];
  if (hostname === process.env.REACT_APP_CREATOR_ROUTE) {
    return "creator";
  } else if (hostname === "marketplace") {
    return "marketplace";
  }
  return "fans";
};

export const routerLink = (username) => {
  const port = window.location.port ? ":" + window.location.port : "";
  const hotname = process.env.REACT_APP_MAIN_DOMAIN;
  const link = `${window.location.protocol}//${hotname}${port}/${username}`;
  return link;
};

export const makeSecureUrl = (s) => {
  var prefix = "http://";
  var prefixs = "https://";
  if (s) {
    if (s.substr(0, prefix.length) == prefix) {
      s = s.replace(/^http:/, "https:");
    } else if (s.substr(0, prefixs.length) == prefixs) {
      s = s.replace(/^https:/, "https:");
    } else {
      s = prefixs + s;
    }
  }

  return s;
};

export const setThemeColor = (colors) => {
  document.documentElement.removeAttribute("style");
  Object.keys(colors || {}).map((item) => {
    document.documentElement.style.setProperty(item, colors?.[item]);
  });
};

export const setLoginTheme = (theme) => {
  document.documentElement.removeAttribute("style");

  const colors = {
    ...theme,
    "--bg-body": theme?.["--landing-page-body"] ?? "#FFFFFF",
    "--bg-logo": theme?.["--landing-page-poweredBy"] ?? theme?.["--landing-page-inlay"] ?? "#000000",
    "--body-inlay-primary": theme?.["--landing-page-heading"] ?? theme?.["--landing-page-inlay"] ?? "#000000",
    "--landing-page-inlay": theme?.["--landing-page-inlay"] ?? theme?.["--landing-page-inlay"] ?? "#000000",
  };

  Object.keys(colors || {}).map((item) => {
    document.documentElement.style.setProperty(item, colors?.[item]);
  });
};

export const setDefaultFavicon = (cname, assets) => {
  if(cname == "capitanes"){
    return capitanes;
  } else if(cname == "team-germany"){
    return teamGermany;
  } else if(cname == "greenwichrfc"){
    return greenwich;
  } else if (cname == "lvlights") {
    return lvlights;
  } else if (cname == "magicgermany") {
    return magicgermany;
  }
  else {
    return assets.favicon;
  }
};

export const openEncoreWebsite = () => {
  window.open('https://about.encore.fans/', '_blank');
}

export const setLandingBackground = (programData) => {
  if(programData){
    return programData?.landing_page?.bg_images?.mobile;
  }
};

export const calculateRedumptionTiime = (
  config,
  lastRedemption,
  fanEngagement,
  totalEngagement,
  points = 0
) => {
  let seconds = 60;
  let isButtonDisabled = false;
  let isCardHide = false;
  switch (config?.timeMeasurement || 0) {
    case 1:
      seconds = 60 * 60;
      break;
    case 2:
      seconds = 60 * 60 * 24;
      break;
    case 3:
      seconds = 60 * 60 * 24 * 7;
      break;
    default:
      seconds = 60;
      break;
  }

  const milliseconds =
    (config?.multiplier || 1) * seconds * 1000 +
    (lastRedemption || 0) -
    moment().valueOf();
  const humanize = moment.duration(milliseconds, "milliseconds").humanize();

  if (config?.multiplier > 0 && milliseconds > 0) {
    isButtonDisabled = true;
  }
  if (config?.limit > 0) {
    if (config?.limit == fanEngagement) {
      isButtonDisabled = true;
    }
  }

  if (points > -1) {
    if (points < config.points) {
      isButtonDisabled = true;
    }
  }
  if (config?.supply > 0) {
    if (config?.supply == totalEngagement) {
      isButtonDisabled = true;
      if (fanEngagement == 0) {
        isCardHide = true;
      }
    }
  }
  if(config?.hide){
    isCardHide = true;
  }

  return {
    isRedeemed: fanEngagement > 0 && milliseconds < 0,
    isDisabled: isButtonDisabled,
    isCardHide: isCardHide,
    humanize: milliseconds > 0 ? humanize : "",
    isPurchase: points < config.points,
  };
};

export const isValidBrowser = () => {
  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf("Chrome") > -1;

  // Detect Internet Explorer
  let IExplorerAgent =
    userAgentString.indexOf("MSIE") > -1 || userAgentString.indexOf("rv:") > -1;

  // Detect Firefox
  let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

  // Detect Safari
  let safariAgent = userAgentString.indexOf("Safari") > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  // Detect Opera
  let operaAgent = userAgentString.indexOf("OP") > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;

  if (
    chromeAgent ||
    IExplorerAgent ||
    firefoxAgent ||
    safariAgent ||
    operaAgent
  ) {
    return true;
  } else {
    return false;
  }

 
};

export const convertToTitleCase = (str) => {
  if (typeof str !== 'string') {
    return
  }
  const words = str.split('-');
  const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const titleCaseStr = titleCaseWords.join(' ');
  return titleCaseStr;
}

export const isProgramActive = (program) => {
  let flag = true;
  if("config" in (program || {})){
    if("active" in (program?.config || {})){
      if(program?.config?.active==false){
        flag = false;
      }
    }
    
  }
  return flag;
    
}

export const isMobileName = () => {
  var userAgent = navigator.userAgent;

  if (/windows phone/i.test(userAgent)) {
    return 'windows';
  }
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  }

  return false;
};

export const numberToWords = (number) => {
    const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    function convertLessThanThousand(num) {
        if (num === 0) {
            return '';
        } else if (num < 10) {
            return ones[num];
        } else if (num < 20) {
            return teens[num - 10];
        } else if (num < 100) {
            return tens[Math.floor(num / 10)] + ' ' + convertLessThanThousand(num % 10);
        } else {
            return ones[Math.floor(num / 100)] + ' hundred ' + convertLessThanThousand(num % 100);
        }
    }

    if (number === 0) {
        return 'zero';
    }

    if (number === 10) {
      return 'ten';
    }

    const billion = Math.floor(number / 1000000000);
    const million = Math.floor((number % 1000000000) / 1000000);
    const thousand = Math.floor((number % 1000000) / 1000);
    const remainder = number % 1000;

    let result = '';

    if (billion) {
        result += convertLessThanThousand(billion) + ' billion ';
    }
    if (million) {
        result += convertLessThanThousand(million) + ' million ';
    }
    if (thousand) {
        result += convertLessThanThousand(thousand) + ' thousand ';
    }
    if (remainder) {
        result += convertLessThanThousand(remainder);
    }

    return result.trim();
}

export const deepEqual = (obj1, obj2) => {
  // Check if both objects are the same type
  if (typeof obj1 !== typeof obj2) {
      return false;
  }

  // If both are not objects, compare them directly
  if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
      return obj1 === obj2;
  }

  // Check if they have the same number of keys
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
      return false;
  }

  // Compare each key recursively
  for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
      }
  }

  return true;
}

export const fetchIP = async (setIp) => {
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res?.data);
  } catch (error) {
    return error.response;
  }
  
};

export const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return result;
}
// Function to get a cookie by name
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}
// Function to set a cookie
export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
export const getAnonymousId = () => {
  let anonymousId = getCookie("encore_uid");
  if (!anonymousId) {
    anonymousId = generateRandomString(10); // Generate a random string of 10 characters
    setCookie("encore_uid", anonymousId, 365); // Set cookie to expire in 365 days
  }
  return anonymousId;
}

export const screenCheck = (filter, data) => {
  let currentScreen = 0;
  
  Object?.keys(data?.tiers || [])?.sort((a, b) => a - b)?.map((tier, index) => {
    if ((data?.tiers?.[tier]?.limit ?? 1) <= filter?.usedItem) {
      currentScreen = index+1;
    }
  })

  return currentScreen;
}

export const validateFullName = (fullName) => {
  if (fullName === null) {
    return false;
  }
  fullName = fullName.trim();
  if (fullName.indexOf(' ') === -1) {
    return false;
  }

  var names = fullName.split(' ');
  if (names.length < 2) {
    return false;
  }
  for (var i = 0; i < names.length; i++) {
    if (names[i].length === 0) {
      return false;
    }
  }
  return true;
}

export const klaviyoEvent = (type, info) => {
  window.klaviyo?.identify({
    [type] : info
  });
}

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    const formattedPhoneNumber = numericPhoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 $2-$3-$4');
    return formattedPhoneNumber;
  }
  return phoneNumber;
}

export const handleTitle = (item, title) => {
  let newTitle = title;
  const socialHandles = Management.get()?.creator?.socials;
  if (item?.type?.includes('twitter')) {
    newTitle = newTitle?.replace?.("{socialhandle}", socialHandles?.twitterUsername)
  }
  if (item?.type?.includes('instagram')) {
    newTitle = newTitle?.replace?.("{socialhandle}", socialHandles?.instagramUsername)
  }
  if (item?.type?.includes('tiktok')) {
    newTitle = newTitle?.replace?.("{socialhandle}", socialHandles?.tiktokUsername)
  }
  if (item?.type?.includes('youtube')) {
    newTitle = newTitle?.replace?.("{socialhandle}", socialHandles?.youtubeUsername)
  }
  if (item?.type?.includes('facebook')) {
    newTitle = newTitle?.replace?.("{socialhandle}", socialHandles?.facebookUsername)
  }
  return newTitle
}

export const handleDescription = (item, desc) => {
  let tpId = item?.touchpoint?.id;
  let unitOfTime = Management.get()?.touchpoints?.filter((touchpoint) => {
    return tpId === touchpoint?.id;
  })[0]?.config?.minGlobalInterval;
  let newDesc = desc;
  newDesc = newDesc?.replace?.("{unitOfTime}", convertSecondsToHoursMinutesSeconds(unitOfTime));
  return newDesc;
}

export const convertSecondsToHoursMinutesSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursString = hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''}` : '';
  const minutesString = minutes > 0 ? `${minutes} minute${minutes !== 1 ? 's' : ''}` : '';
  const secondsString = remainingSeconds > 0 ? `${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}` : '';

  const timeComponents = [hoursString, minutesString, secondsString].filter(Boolean);

  let result = '';
  if (timeComponents.length === 1) {
      result = timeComponents[0];
  } else if (timeComponents.length === 2) {
      result = timeComponents.join(' and ');
  } else if (timeComponents.length === 3) {
      result = timeComponents.slice(0, 2).join(', ') + ', and ' + timeComponents[2];
  }

  return result;
}

export const setMarketTheme = () => {
  document.documentElement.removeAttribute("style");

  let colors = {
    "--bg-body": "#fff",
    "--landing-page-inlay": "#fff",
    "--bg-tile": "#000",
    "--btn-inlay-primary": "#fff",
    "--body-inlay-primary": "#000",
    "--tile-inlay-primary": "#000",
    "--bg-logo": "#fff",
    "--bg-btn": "#f6104e",
    "--bg-tile-form": "#f3f3f3"
  };

  Object.keys(colors || {}).map((item) => {
    document.documentElement.style.setProperty(item, colors?.[item]);
  });
};

export const appendToUrl = (url, param) => {
  if (url.endsWith('/')) {
      return url + param;
  } else {
      return url + '/' + param;
  }
}

export const isOrlando = () => {
  const  management = Management.get();
  const cname = management?.creator?.username;
  return cname === "magicgermany";
}