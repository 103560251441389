import * as React from "react";

type Props = {};

const OrlandoMagicLandingText: React.FC<Props> = (props) => {
    return (
		<div className="text-start mt-24 pb-8 lg:mb-10 px-[25px] lg:px-[380px] btn-inlay-primary">
			<div className="text-[13px] font-bold text-center">
                Gewinnspiel „Traumreise nach Orlando“
			</div>
			<div className="text-[13px] font-bold text-center mt-3">
                Oﬃzielle Regeln
			</div>
			<div className="text-[13px] font-bold text-start mt-3">
                Um teilzunehmen oder zu gewinnen ist kein Kauf erforderlich.
			</div>
			<div className="text-[13px] font-bold text-start mt-3">
                Ungültig, wo es verboten.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"1) "}Beschreibung</span>: 
				Das Gewinnspiel Traumreise nach Orlando (
				<span className="font-bold text-[13px]">„Werbeaktion“</span>) ist eine Werbeaktion mit
                Zufallsziehung. Die Gewinnchancen hängen von der während des Zeitraums der Aktion eingehenden
                Gesamtzahl an teilnahmeberechtigten Einsendungen ab. Die Werbeaktion wird von Encore Fans Inc. (
				<a className="underline ring-color" href="mailto:hello@encore.fans">
					hello@encore.fans
				</a>
				) der (
				<span className="font-bold text-[13px]"> „Veranstalter“</span>) verwaltet.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"2) "}Teilnahmeberechtigung</span>: Bei dieser Werbeaktion sind Personen mit ständigem Wohnsitz in Deutschland teilnahmeberechtigt, die zum Zeitpunkt ihrer Teilnahme mindestens achtzehn (18) Jahre alt sind (jede ein <span className="font-bold text-[13px]"> „Teilnehmer"</span>). Der Veranstalter behält sich das Recht vor, die Teilnahmeberechtigung jedes Teilnehmers zu überprüfen.
			</div>

			<div className="text-[13px] font-normal mt-2">
				Mitarbeiter, leitende Angestellte, Direktoren, Agenten und Vertreter des Veranstalters, Orlando Magic, Ltd. (<span className="font-bold text-[13px]"> "Sponsor"</span>), der National Basketball Association (<span className="font-bold text-[13px]"> „NBA“</span>) und ihre Mitgliedsteams, NBA Properties Inc., und NBA Media Ventures, LLC (zusammen die <span className="font-bold text-[13px]"> „NBA-Unternehmen"</span>) und alle ihre jeweiligen Muttergesellschaften, Tochtergesellschaften, verbundenen Unternehmen, leitenden Angestellten,  Geschäftsführer,  Eigentümer,  Vertriebshändler,  Einzelhändler,  Agenten, Werbe-/Promotion-Agenturen (im Folgenden zusammen die <span className="font-bold text-[13px]"> „freigestellten Parteien"</span>) sowie deren unmittelbare Familienangehörige (Mutter, Vater, Schwester, Bruder, Kind, Ehemann, Ehefrau und ihre jeweiligen Ehegatten, unabhängig von ihrem Wohnort) und alle im selben Haushalt lebende Personen, unabhängig davon, ob sie verwandt sind oder nicht, sind nicht berechtigt, an der Werbeaktion teilzunehmen oder einen Preis zu erhalten.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"3) "}Laufzeit der Werbeaktion</span>: Die Werbeaktion beginnt am 19. Dezember 2024 um 16:00 Uhr MEZ und endet am 21. Januar 2025 um 23:59 Uhr MEZ (<span className="font-bold text-[13px]"> „Aktionszeitraum"</span>). Die Anmeldung und die Einsendungen müssen während des Aktionszeitraums eingereicht werden. Nach dem Aktionszeitraum eingereichte Einträge werden disqualiﬁziert.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"4) "}Um teilzunehmen</span>: Besuchen Sie während des Aktionszeitraums <a className="underline text-[13px] ring-color" href="http://orlandomagic.com/UltimativeReise" rel="noreferrer" target="_blank">http://orlandomagic.com/UltimativeReise</a> (<span className="font-bold text-[13px]">„Website"</span>) und klicken auf die Banner, Schaltﬂächen und/oder Links, um auf die Anmeldeseite der Werbeaktion zu gelangen (die „Aktionsregistrierung"). Um sich zur Teilnahme anzumelden, füllen Sie bitte das Anmeldeformular für die Werbeaktion entsprechend den Anweisungen aus und reichen Sie es ein. Schließen Sie zwei (2) beliebige Aufgaben ab, um teilnehmen und Ihre Chance auf den Gewinn nutzen zu können:
			</div>

			<ul className="list-disc list-inside mt-3">
				<li className="text-[13px] font-normal">Abonnieren Sie die Orlando Magic eNews</li>
				<li className="text-[13px] font-normal">Folgen Sie Orlando Magic Germany (Identiﬁkator de_orlandomagic) auf Instagram</li>
				<li className="text-[13px] font-normal">Folgen Sie Orlando Magic Germany (Identiﬁkator orlandomagic_de) auf Tik Tok</li>
				<li className="text-[13px] font-normal">Laden Sie die App Orlando Magic herunter</li>
			</ul>

			<div className="text-[13px] font-normal mt-2">
				Außerdem könnten Sie gefragt werden, ob Sie Werbe-E-Mails von den Orlando Magic erhalten möchten. Die Zustimmung, Werbe-E-Mails zu erhalten, ist freiwillig und nicht Voraussetzung, um an dieser Aktion teilnehmen zu können, und sie wirkt sich auch nicht auf Ihre Gewinnchancen aus.
			</div>

			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">Während des Aktionszeitraums darf pro Person und E-Mail-Adresse nur ein (1) Anmeldeformular für die Aktion eingereicht werden. Zusätzliche Anmeldeformulare oder Einsendungen, die von derselben Person oder E-Mail-Adresse stammen, von der wir bereits eine Einsendung oder E-Mail-Adresse erhalten haben, sind ungültig. Es ist untersagt, Skripte, Makros oder andere Hilfsmittel zu verwenden, um das Anmeldeverfahren zu automatisieren oder zu unterwandern und alle derartigen Anmeldungen werden für ungültig erklärt. Ein vollständiger Eintrag umfasst alle Pﬂichtfelder im Registrierungsformular, die vom Teilnehmer korrekt ausgefüllt wurden. Unvollständige Einsendungen werden nicht akzeptiert und automatisch disqualiﬁziert. Einsendungen, die im Namen einer anderen Person eingereicht werden, werden nicht akzeptiert, und gemeinsame Einreichungen sind nicht zulässig. Der Veranstalter behält sich das Recht vor, Personen zu disqualiﬁzieren, bei denen festgestellt wird, dass sie automatisierte Methoden oder Skripte zur Teilnahme verwendet haben.</span>
			</div>

			<div className="text-[13px] font-normal mt-2">
				Die Registrierung und Teilnahme an der Werbeaktion sind kostenlos.
			</div>


			<div className="text-[13px] font-normal mt-2">
                <span className="font-bold text-[13px]">{"5) "} Preis</span>: Bei der Werbeaktion wird es nur einen (1) Hauptgewinner oder eine Hauptgewinnerin geben (den „Hauptgewinner“).
            </div>

            <ul className="list-disc list-inside mt-3">
                <li className="text-[13px] font-normal">
                    Der Hauptgewinn besteht aus den folgenden Teilen mit einem ungefähren Gesamtwert von
                    <span className="bg-yellow-400 text-[13px] font-normal">
                        4.410 U.S. Dollars
                    </span>
                    (zusammen der „Hauptgewinn”; alle Einzelheiten und Angaben zum Hauptgewinn werden vom Veranstalter und/oder Sponsor nach eigenem Ermessen festgelegt):
                </li>
            </ul>

            <ul className="list-disc list-inside mt-3 ml-9">
                <li className="text-[13px] font-normal">
                    Zwei (2) Hin- und Rückﬂugtickets in der Economy-Klasse von Berlin, Deutschland, nach Orlando, Florida. Die genaue Flug- und Sitzplatzauswahl liegt im alleinigen Ermessen des Sponsors.
                </li>
                <li className="text-[13px] font-normal">
                    Das Datum und die Uhrzeit der Flüge werden vom Sponsor nach eigenem Ermessen festgelegt.
                </li>
                <li className="text-[13px] font-normal">
                    Ein (1) Hotelzimmer für zwei (2) Personen in Orlando, Florida, für insgesamt vier (4) Nächte. Die genauen Daten des Hotelaufenthalts werden vom Sponsor nach eigenem Ermessen festgelegt. Hotel und Zimmer werden vom Sponsor nach eigenem Ermessen ausgewählt.
                </li>
                <li className="text-[13px] font-normal">
                    Zwei (2) Eintrittskarten für das Spiel Orlando Magic gegen Dallas Mavericks am Donnerstag, den 27. März 2025, im Kia Center, 400 W Church St, Orlando, Florida (der „Veranstaltungsort“). Die Art der Eintrittskarten werden vom Sponsor nach eigenem Ermessen festgelegt.
                </li>
                <li className="text-[13px] font-normal">
                    Treﬀen und Gespräch mit Franz Wagner, Moritz Wagner und Tristan da Silva nach dem Spiel.
                </li>
                <li className="text-[13px] font-normal">
                    Der Gewinner oder die Gewinnerin des Hauptgewinns kann eine (1) Person aus seinem oder ihrem Freundes- oder Familienkreis benennen, die ihn oder sie begleitet (der „Gast“).
                </li>
            </ul>

            <div className="text-[13px] font-normal mt-2">
                Alle anderen Kosten und Ausgaben, die mit der Annahme und Nutzung des Hauptgewinns verbunden und hier nicht als zuerkannt aufgeführt sind, gehen zu Lasten des Gewinners bzw. der Gewinnerin, dazu gehören, ohne darauf beschränkt zu sein, Transportkosten am Boden, Trinkgelder, Waren, Telefongespräche, persönliche Ausgaben jeglicher Art für Zwischenübernachtungen, Speisen und Getränke, Servicegebühren, Mahlzeiten und Unterhaltung während des Fluges; die Kosten für die An- und Abreise zum und vom Abreiseort des Hauptpreises, den Abschluss einer ausreichenden persönlichen Reiseversicherung vor der Abreise, falls gewünscht, die Beschaﬀung und Mitnahme aller erforderlichen Reisedokumente wie Pässe und Visa sowie die Einhaltung der Einreise-, Gesundheits-, Zoll- und Einwanderungsbestimmungen und -anforderungen.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Der Gewinner des Hauptpreises und der Gast müssen beide mindestens 18 Jahre alt sein, es sei denn, der Gast ist das Kind oder eine andere Person, für die der Gewinner die elterliche Verantwortung oder die gesetzliche Aufsichtspﬂicht hat.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Der Hauptpreisgewinner und der Gast sind dafür verantwortlich, alle notwendigen Reisedokumente, wie beispielsweise Reisepässe und Visa, zu beschaﬀen und mit sich zu führen und die Einreise-, Gesundheits-, Zoll- und Einwanderungsbestimmungen und -anforderungen einzuhalten.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Für den Fall, dass der Gewinner des Hauptpreises oder der Gast nicht in der Lage sind, den Hauptpreis anzunehmen oder aus irgendeinem Grund nicht reisen oder an einem Teil des Hauptpreises teilnehmen können, verliert der betreﬀende Hauptpreisgewinner den Hauptpreis in seiner Gesamtheit und der Hauptpreis wird, sofern es die Zeit erlaubt, an einen alternativen Hauptpreisgewinner vergeben, der nach dem Zufallsprinzip aus allen anderen teilnahmeberechtigten Teilnehmern ausgewählt wird. Der Sponsor behält sich das Recht vor, den Hauptpreis zu verschieben oder durch Gegenstände/Aktivitäten von vergleichbarem oder höherem Wert zu ersetzen, die allein vom Sponsor bestimmt werden, und in einem solchen Fall wird den Gewinnern kein zusätzlicher Entschädigungspreis, Bargeld oder eine andere Alternative gewährt.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Wenn der Hauptpreis aus irgendeinem Grund storniert oder aufgeschoben wird, wird der Sponsor den Hauptpreis durch einen oder mehrere Gegenstände/Unternehmung(en) von vergleichbarem oder höherem Wert ersetzen, die ausschließlich vom Sponsor bestimmt werden, und dem Hauptpreisgewinner wird in einem solchen Fall kein zusätzlicher Entschädigungspreis, Bargeld oder eine andere Alternative gewährt.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Der Hauptpreisgewinner und der Gast müssen sich an alle Regeln und Vorschriften des Veranstaltungsortes halten. Andernfalls kann der Hauptpreis verfallen. Die Einzelheiten zu den Eintrittskarten und Sitzplätzen am Veranstaltungsort für die Spiele der Orlando Magic werden vom Sponsor nach eigenem Ermessen festgelegt. Der Hauptpreis sind nicht verhandelbar oder übertragbar.
            </div>


			<div className="text-[13px] font-normal mt-2">
                <span className="font-bold text-[13px]">
                    {"6) "} Ermittlung des Gewinners
                </span>
                    : Gegen 5:00 Uhr MEZ wird am 23. Januar 2025 (das
                    <span className="font-bold text-[13px]">„Ziehungsdatum"</span>) aus allen in 
                    Übereinstimmung mit diesen (vom Sponsor nach eigenem Ermessen festgelegten) Regeln 
                    eingegangenen und berechtigten Einsendungen nur ein (1) potenzieller Hauptpreisgewinner 
                    per Zufallsprinzip ausgewählt (die <span className="font-bold text-[13px]">„Gewinner"</span>). 
                    Die Gewinnchancen hängen von der während des Aktionszeitraums beim Sponsor eingehenden 
                    Gesamtzahl an teilnahmeberechtigten Einsendungen ab.
            </div>

			<div className="text-[13px] font-normal mt-2">
                <span className="font-bold text-[13px]">
                    {"7) "} Benachrichtigung des Gewinners
                </span>
                : Der Gewinner wird am oder um den 27. Januar 2025 mit einer E-Mail an die bei der Registrierung angegebene E-Mail-Adresse benachrichtigt. Die Teilnehmer sind allein dafür verantwortlich, ihren E-Mail-Posteingang zu überprüfen, um festzustellen, ob sie zu den Gewinnern gehören.
            </div>

            <div className="text-[13px] font-normal mt-2">
                <span className="font-bold text-[13px]">
                    Um einen Preis beanspruchen zu können, muss jeder Gewinner innerhalb von zweiundsiebzig
                    (72) Stunden nach dem Versand der E-Mail mit der Gewinnbenachrichtigung die Anweisungen in der E-Mail-Benachrichtigung befolgen und ausfüllen, die diesem Gewinner zugesandt wird.
                </span>{" "}
                Wenn ein Gewinner den Preis nicht innerhalb dieses Zeitrahmens beansprucht, verfällt der Anspruch des Gewinners automatisch und der Sponsor vergibt den Preis erneut an einen anderen Gewinner, der in Übereinstimmung mit diesen offiziellen Regeln per Zufallsprinzip ausgewählt wird.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Sollte ein Preis bis zum 5. Februar 2025 um 12:00 Uhr MEZ nicht beansprucht worden sein, behält sich der Sponsor das Recht vor, die Zuerkennung des nicht beanspruchten Preises zu stornieren.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Der Sponsor haftet nicht, wenn ein Gewinner den Preis nicht in Anspruch nimmt.
            </div>

            <div className="text-[13px] font-normal mt-2">
            Die potenziellen Gewinner und der Gast (falls zutreffend) müssen innerhalb eines (1) Tages nach Erhalt der Dokumente eine eidesstattliche Erklärung über die Teilnahmeberechtigung, eine Haftungsfreistellung und alle anderen vom Sponsor geforderten Dokumente ausfüllen und zurücksenden. Der Gast (oder der Gewinner, wenn der Gast das Kind oder der gesetzliche Vormund des Gewinners ist) muss außerdem innerhalb eines (1) Tages nach Erhalt der Dokumente eine eidesstattliche Erklärung über die Teilnahmeberechtigung, eine Haftungsfreistellung und alle anderen vom Sponsor geforderten Dokumente ausfüllen und zurücksenden.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Falls der Gewinner oder Gast den Preis aus irgendeinem Grund nicht annehmen oder die Reise antreten kann, verfällt der gesamte Preis. Sofern die Zeit es erlaubt, wird der Preis einem anderen Gewinner zuerkannt, der nach dem Zufallsprinzip aus den teilnahmeberechtigten Teilnehmern ausgewählt wird. Der Sponsor behält sich das Recht vor, den Preis nach eigenem Ermessen zu verschieben oder durch einen oder mehrere Gegenstände oder Aktivitäten von vergleichbarem oder höherem Wert zu ersetzen, ohne dass dem Gewinner eine zusätzliche Entschädigung zusteht.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Wenn die E-Mail, mit der ein Gewinner benachrichtigt wird, als unzustellbar zurückkommt oder wenn ein potenzieller Gewinner diese offiziellen Regeln nicht einhält, wird dieser potenzielle Gewinner disqualifiziert und der Sponsor vergibt den betreffenden Preis erneut an einen anderen Gewinner, der in Übereinstimmung mit diesen offiziellen Regeln ausgewählt wird.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Die Preise werden (vorbehaltlich der Überprüfung der Teilnahmeberechtigung und der Einhaltung der Bedingungen dieser offiziellen Regeln) der Person zuerkannt, die auf dem entsprechenden Anmeldeformular für die Werbeaktion angegeben ist, unabhängig davon, ob es sich dabei um den berechtigten Kontoinhaber der E-Mail-Adresse handelt, von der aus das Anmeldeformular für die Werbeaktion eingereicht wurde oder nicht.
            </div>


			<div className="text-[13px] font-normal mt-2">
                <span className="font-bold text-[13px]">{"8) "} Sonstiges</span>: Durch die Teilnahme an der Werbeaktion akzeptiert jeder Teilnehmer diese offiziellen Regeln und die Entscheidungen des Veranstalters und/oder des Sponsors, die in jeder Hinsicht endgültig und bindend sind, und erklärt sich damit einverstanden,vorausgesetzt, dass diese Entscheidungen fair und in Übereinstimmung mit diesen offiziellen Regeln getroffen werden.
            </div>

            <div className="text-[13px] font-normal mt-2">
            Eine Übertragung, Abtretung, Barauszahlung oder ein Austausch des Preises (oder eines Teils davon) ist nicht gestattet.
            </div>

            <div className="text-[13px] font-normal mt-2">
            Die freigestellten Parteien und ihre Muttergesellschaften, verbundenen Unternehmen und Tochtergesellschaften sowie Werbe- und Promotionsagenturen sind nicht verantwortlich für verlorene, unvollständige, ungenaue, verstümmelte, verspätete, nicht zugestellte oder fehlgeleitete Einsendungen, Anmeldungen, Benutzernamen, NBA-Team-Beiträge (einschließlich, ohne darauf beschränkt zu sein, Vorhersagen, Auswahlkriterien oder Änderungen); Diebstahl, Zerstörung oder den unbefugten Zugang zu oder die Änderung von NBA Teams; Ausfälle oder Fehlfunktionen von Telefonen/Mobilgeräten (einschließlich drahtloser Telefone/Handys oder anderer drahtloser Geräte); Unterbrechung oder Nichtverfügbarkeit von Netzwerken, Servern, Internet-Service-Providern (ISP) oder anderen Verbindungen; Fehler, Auslassungen, Unterbrechungen, Defekte oder Verzögerungen bei Übertragungen oder Kommunikationsfehler; Datenstaus im Internet oder technische Probleme, einschließlich, ohne darauf beschränkt zu sein, Verletzungen oder Schäden am Computer oder Mobilgerät des Teilnehmers oder einer anderen Person, die mit der Teilnahme an dieser Aktion in Verbindung steht oder sich daraus ergibt; Druckfehler in werbebezogenen Anzeigen oder anderen Materialien; Absagen, Aussetzungen oder Vorverlegungen von NBA-Spielen, Fehler bei der Punktetabelle oder Zeitplanung.
            </div>

            <div className="text-[13px] font-normal mt-2">
            Mit der Teilnahme erklärt sich jeder Teilnehmer damit einverstanden, dass die freigestellten Parteien keine Haftung übernehmen und vom Teilnehmer für alle Ansprüche, Handlungen, Haftungen, Verluste, Verletzungen oder Schäden (x) an persönlichem oder materiellem Eigentum, die ganz oder teilweise, direkt oder indirekt auf die Annahme, den Besitz, die Verwendung oder den Missbrauch eines Preises, andere mit dem Preis verbundene Handlungen, Reisen oder reisebezogene Handlungen oder die Teilnahme an dieser Werbeaktion zurückzuführen sind, und (y) alle Steuern und sonstigen Gebühren, die auf den vom Gewinner oder Gast erhaltenen Preis erhoben werden, schadlos gehalten werden. Nichts in diesen offiziellen Regeln schließt die Haftung der freigestellten Parteien für Tod oder Körperverletzung aus, die durch ihre Fahrlässigkeit verursacht wurden, oder für jede andere Angelegenheit, bei der die Haftung von Rechts wegen nicht beschränkt werden kann.
            </div>

            <div className="text-[13px] font-normal mt-2">
            Die freigestellten Parteien behalten sich nach eigenem Ermessen das Recht vor: (1) jede Person zu disqualifizieren (und ihre Punkte für ungültig zu erklären), die das Einsendeverfahren, den Ablauf der Werbeaktion oder eine andere Komponente dieser Werbeaktion beeinträchtigt oder anderweitig gegen diese offiziellen Regeln verstößt; und 2) diese Werbeaktion (oder einen Teil davon) auf das notwendige Minimum zu beschränken, auszusetzen oder zu beenden, falls Viren, Bugs, nicht autorisierte menschliche Eingriffe oder andere Ursachen, einschließlich, ohne darauf beschränkt zu sein, Krieg, Streiks und/oder höhere Gewalt, die Verwaltung, Sicherheit, Fairness oder den ordnungsgemäßen Ablauf dieser Werbeaktion stören oder beeinträchtigen. Die freigestellten Parteien behalten sich das Recht vor, die Werbeaktion jederzeit während des Aktionszeitraums zu stoppen oder zu ändern, wenn Ereignisse außerhalb ihrer Kontrolle die Fairness oder Integrität der Werbeaktion beeinträchtigen. Jede derartige Änderung wird den Teilnehmern rechtzeitig mitgeteilt und es werden, falls erforderlich, alternative Vereinbarungen oder Entschädigungen in Betracht gezogen, um eine unfaire Benachteiligung der Teilnehmer zu vermeiden.
            </div>

            <div className="text-[13px] font-normal mt-2">
            Der Veranstalter und/oder der Sponsor behält sich das Recht vor, diese Regeln zum Zwecke der Klarstellung zu ändern, ohne die offiziellen Regeln der Werbeaktion wesentlich zu beeinflussen. Dies ist eine Werbeaktion, die darauf abzielt, den Bekanntheitsgrad und das Interesse der Verbraucher an den Dienstleistungen der freigestellten Parteien zu erhöhen.
            </div>

            <div className="text-[13px] font-normal mt-2">
            Für den Fall, dass die Werbeaktion von einer Rechts- oder Aufsichtsbehörde angefochten wird, behalten sich die freigestellten Parteien das Recht vor, die Werbeaktion abzubrechen oder zu ändern. In einem solchen Fall übernimmt der Sponsor keine Haftung gegenüber Teilnehmern, die aufgrund eines solchen Vorgangs disqualifiziert werden. Im Falle von Unklarheiten oder Fehlern in diesen offiziellen Regeln behalten sich die freigestellten Parteien das Recht vor, diese offiziellen Regeln zum Zwecke der Klarstellung zu ändern oder diese Unklarheiten oder Fehler zu korrigieren, ohne die offiziellen Regeln der Werbeaktion wesentlich zu beeinflussen.
            </div>

			<div className="text-[13px] font-normal mt-2">
                <span className="font-bold text-[13px]">
                    {"9) "} Haftungsbeschränkung und Gewährleistungsausschluss
                </span>
                :{" "}
                <span className="uppercase text-[13px] font-normal">
                IN KEINEM FALL SIND DIE FREIGESTELLTEN PARTEIEN ODER IHRE JEWEILIGEN LEITENDEN ANGESTELLTEN, DIREKTOREN, MITARBEITER, VERTRETER UND VERMITTLER VERANTWORTLICH ODER HAFTBAR FÜR SCHÄDEN ODER VERLUSTE, WELCHER ART AUCH IMMER, EINSCHLIESSLICH, OHNE DARAUF BESCHRÄNKT ZU SEIN, DIREKTE, INDIREKTE, ZUFÄLLIGE ODER FOLGESCHÄDEN, DIE SICH AUS IHREM ZUGANG ZU UND IHRER NUTZUNG DER PREISE UND/ODER DER WERBEAKTION, DEM HERUNTERLADEN UND/ODER AUSDRUCKEN VON MATERIAL ERGEBEN, DAS VON DER WERBEAKTION HERUNTERGELADEN WURDE. IN KEINEM FALL WIRD DIE GESAMTHAFTUNG DER FREIGESTELLTEN PARTEIEN IHNEN GEGENÜBER FÜR ALLE SCHÄDEN, VERLUSTE ODER KLAGEGRÜNDE MEHR ALS 100 $US BETRAGEN. OHNE EINSCHRÄNKUNG DES VORSTEHENDEN WIRD ALLES IM RAHMEN DER WERBEAKTION, EINSCHLIESSLICH DER PREISE, OHNE JEGLICHE AUSDRÜCKLICHE ODER STILLSCHWEIGENDE GARANTIE, EINSCHLIESSLICH DER STILLSCHWEIGENDEN GARANTIE DER MARKTGÄNGIGKEIT, DER EIGNUNG FÜR EINEN BESTIMMTEN ZWECK ODER DER NICHTVERLETZUNG VON RECHTEN – OHNE DARAUF BESCHRÄNKFT ZU SEIN – WIE BESEHEN ZUR VERFÜGUNG GESTELLT. IN EINIGEN GERICHTSBARKEITEN IST DIE BESCHRÄNKUNG ODER DER AUSSCHLUSS DER HAFTUNG FÜR NEBEN- ODER FOLGESCHÄDEN ODER DER AUSSCHLUSS STILLSCHWEIGENDER GARANTIEN MÖGLICHERWEISE NICHT ZULÄSSIG, SO DASS EINIGE DER OBEN GENANNTEN BESCHRÄNKUNGEN ODER AUSSCHLÜSSE MÖGLICHERWEISE NICHT AUF SIE ZUTREFFEN. ÜBERPRÜFEN SIE IHRE ÖRTLICHEN GESETZE AUF ETWAIGE AUSSCHLÜSSE ODER BESCHRÄNKUNGEN ODER AUSSCHLÜSSE DIESER BESCHRÄNKUNGEN.
                </span>
            </div>

			<div className="text-[13px] font-normal mt-2">
                <span className="font-bold text-[13px]">{"10) "} Datenschutz</span>: Die vom Teilnehmer übermittelten Informationen unterliegen den Datenschutzrichtlinien des Sponsors und des Veranstalters. Der Sponsor und der Veranstalter erfassen die personenbezogenen Daten der Teilnehmer, wenn diese an der Werbeaktion teilnehmen. Die auf der Website erhobenen personenbezogenen Daten unterliegen den Datenschutzbestimmungen des Sponsors, die unter folgender Adresse zu ﬁnden sind
                <a
                    className="underline text-[13px] ring-color"
                    href={"https://www.nba.com/privacy-policy"}
                    rel="noreferrer"
                    target={"_blank"}
                >
                    https://www.nba.com/privacy-policy
                </a>{" "}
                und der Datenschutzrichtlinie des Veranstalters, die Sie unter
                <a
                    className="underline text-[13px] ring-color"
                    href={"https://legal.encore.fans/Encore_Privacy_Policy.pdf"}
                    rel="noreferrer"
                    target={"_blank"}
                >
                    https://legal.encore.fans/Encore_Privacy_Policy.pdf
                </a>{" "}
                ﬁnden, und werden im Einklang mit diesen Richtlinien und vorbehaltlich der dem Sponsor mitgeteilten speziﬁschen Datenschutzentscheidungen behandelt. Mit der Teilnahme am Gewinnspiel bestätigt der Teilnehmer, dass er die Datenschutzrichtlinien gelesen und akzeptiert hat, und er mit der Erhebung, Nutzung und Weitergabe seiner personenbezogenen Daten in Übereinstimmung mit den Richtlinien einverstanden ist. Mit der Teilnahme am Gewinnspiel erklärt sich der Teilnehmer auch damit einverstanden, dass er/sie, falls er/sie als Gewinner ausgewählt wird, an die Allgemeinen Geschäftsbedingungen des Sponsors gebunden ist.
            </div>

            <div className="text-[13px] font-normal mt-2">
                Weitere Fragen zu dieser Richtlinie können an folgende Adresse gerichtet werden:
            </div>

            <ul className="list-disc list-inside mt-3">
                <li className="text-[13px] font-normal">
                    Richten Sie Ihre Zuschriften an Orlando Magic, Ltd, 400 W, Church St., Suite 250, Orlando, Florida, 32801, Attn: Legal Services.
                </li>
                <li className="text-[13px] font-normal">
                    Per E-Mail an
                    <a className="underline ring-color" href="mailto:hello@encore.fans">
                        hello@encore.fans
                    </a>
                </li>
            </ul>

            <div className="text-[13px] font-normal mt-2">
                Personenbezogene Daten können auch an Dritte weitergegeben werden, aber ausschließlich, um die Werbeaktion zu verwalten und den Preis zu vergeben.
            </div>

			<div className="text-[13px] font-normal mt-2">
                <span className="font-bold text-[13px]">{"11) "}</span> Diese oﬃziellen Regeln unterliegen deutschem Recht. 
                Alle Streitigkeiten oder Ansprüche, die sich im Zusammenhang mit der Werbeaktion oder diesen oﬃziellen Regeln ergeben, fallen in die ausschließliche Zuständigkeit der Gerichte in Deutschland.
            </div>


			<div className="text-[13px] font-normal mt-2">
                <span className="font-bold text-[13px]">{"12) "}</span> Die Werbeaktion
                und die in diesen oﬃziellen Regeln enthaltenen Geschäftsbedingungen
                unterliegen allen Regeln, Vorschriften, Beschlüssen und Vereinbarungen
                der National Basketball Association (
                <span className="font-bold text-[13px]">„NBA"</span>), wie sie von Zeit
                zu Zeit geändert oder ergänzt werden können.
            </div>


			<div className="text-[13px] font-normal mt-2">
				<span className="font-bold text-[13px]">{"13) "}</span> Sponsor: Orlando
				Magic, LLC, 400 W, Church St., Suite 250, Orlando, Florida, 32801. Veranstalter: Encore Fans Inc. 
				<a className="underline ring-color" href="mailto:hello@encore.fans">
					hello@encore.fans
				</a>
			</div>
		</div>
	);
};


export default OrlandoMagicLandingText;
